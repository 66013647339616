import React, { useState, useEffect } from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import Arbitrum from "./views/Arbitrum";
import ArbitrumTestnet from "./views/ArbitrumTestnet";
import "./App.css";
import { FaTimes } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { RiMenuLine } from "react-icons/ri";
import mycLogo from "./img/logo_MYC.svg";

function AppHeaderLinks({ mode, small, clickCloseIcon }) {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <div
            className="App-header-menu-icon-block"
            onClick={() => clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
          <NavLink
            exact
            activeClassName="active"
            className="App-header-link-main"
            to="/"
          >
            <img src={mycLogo} alt="MYC Logo" />
          </NavLink>
        </div>
      )}
      <div className="App-header-link-container">
        <NavLink to="/" exact className="nav-link" activeClassName="active">
          Arbitrum
        </NavLink>
      </div>
      <div className="App-header-link-container">
        <NavLink to="/arbitrum-rinkeby" className="nav-link">
          Arbitrum Testnet
        </NavLink>
      </div>
    </div>
  );
}

const App = () => {
  const [mode, setMode] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const slideVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 },
  };

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    const savedMode = window.localStorage.getItem("mode");
    const targetMode = savedMode == "light" ? "light" : "dark";
    document.querySelector("body").style.backgroundColor =
      targetMode == "dark" ? "var(--background-primary)" : "#f6f9ff";
    setMode(targetMode);
  }, []);

  const switchMode = () => {
    const targetMode = mode == "dark" ? "light" : "dark";
    window.localStorage.setItem("mode", targetMode);
    document.querySelector("body").style.backgroundColor =
      targetMode == "dark" ? "var(--background-primary)" : "#f6f9ff";
    setMode(targetMode);
  };

  return (
    <Switch>
      {mode && (
        <div
          className={cx("App", mode, {
            ["full-width"]: !isSidebarOpen,
          })}
        >
          {isDrawerVisible && (
            <AnimatePresence>
              {isDrawerVisible && (
                <motion.div
                  className="App-header-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                ></motion.div>
              )}
            </AnimatePresence>
          )}
          <nav className="nav">
            <div className="nav-right">
              <div>
                <div
                  className="App-header-menu-icon-block"
                  onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                >
                  {!isDrawerVisible && (
                    <RiMenuLine className="App-header-menu-icon" />
                  )}
                  {isDrawerVisible && (
                    <FaTimes className="App-header-menu-icon" />
                  )}
                </div>
                <a
                  href="https://mycelium.xyz"
                  target="_blank"
                  className="nav-logo"
                  rel="noreferrer"
                >
                  <img src={mycLogo} />
                </a>
              </div>
              <div>
                <NavLink
                  exact
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                >
                  Arbitrum
                </NavLink>
                <NavLink
                  to="/arbitrum-rinkeby"
                  className="nav-link"
                  activeClassName="active"
                >
                  Arbitrum Testnet
                </NavLink>
                <div className="divider">|</div>
                <a
                  href="https://swaps.mycelium.xyz"
                  target="_blank"
                  className="nav-link"
                  rel="noreferrer"
                >
                  Perpetual Swaps
                </a>
                <a
                  href="https://docs.mycelium.xyz"
                  target="_blank"
                  className="nav-link"
                  rel="noreferrer"
                >
                  Docs
                </a>
              </div>
            </div>
          </nav>
          <AnimatePresence>
            {isDrawerVisible && (
              <motion.div
                onClick={() => setIsDrawerVisible(false)}
                className="App-header-links-container App-header-drawer"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={slideVariants}
                transition={{ duration: 0.2 }}
              >
                <AppHeaderLinks
                  mode={mode}
                  small
                  clickCloseIcon={() => setIsDrawerVisible(false)}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <div className="content">
            <Route
              exact
              path="/"
              render={(props) => (
                <Arbitrum
                  {...props}
                  chainName={"arbitrum"}
                  mode={mode}
                  isSidebarOpen={isSidebarOpen}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              )}
            />
            <Route
              exact
              path="/arbitrum-rinkeby"
              render={(props) => (
                <Arbitrum
                  {...props}
                  chainName={"arbitrumTestnet"}
                  mode={mode}
                  isSidebarOpen={isSidebarOpen}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              )}
            />
          </div>
        </div>
      )}
    </Switch>
  );
};

export default App;
