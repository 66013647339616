export const BSC = 56
export const ARBITRUM = 42161
export const ARBITRUM_TESTNET = 421611
export const AVALANCHE = 43114

export const addresses = {
    [ARBITRUM]: {
        GMX: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
        BTC: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
        ETH: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        LINK: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
        UNI: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
        DAI: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        FXS: '0x9d2f299715d94d8a7e6f5eaa8e654e8c74a988a7',
        CRV: '0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
        BAL: '0x040d1edc9569d4bab2d15287dc5a4f10f56a56b8',

        RewardReader: '0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0',
        MLP: '0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258',
        MlpManager: '0x321F653eED006AD1C29D174e17d96351BDe22649'
    },

    [ARBITRUM_TESTNET]: {
        GMX: '0xf020d40b187A7973F5Be92f5826Be55dCa229ba7',
        BTC: '0x5360425C5dd9a3B3a41F619515F9318caA34CfC9',
        ETH: '0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681',
        LINK: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
        UNI: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
        RewardReader: '0xE6c94E0d0EA43FF89f0952E97eD3d2cD89DB4C12',
        MLP: '0x4960Eb3D3686943E365f71602c778C8b484B80a9',
        MlpManager: '0x23f1B98a8725eDa20a83c8706bA3136B260B152D'
    },
}

export function getAddress(chainId, key) {
    if (!(Object.keys(addresses).includes(chainId))) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
