import { useMemo, useState, useEffect } from "react";
import { ApolloClient, InMemoryCache, gql, HttpLink } from "@apollo/client";
import { chain, sumBy, sortBy, maxBy, minBy } from "lodash";
import fetch from "cross-fetch";
import * as ethers from "ethers";

import { fillPeriods } from "./helpers";
import { getAddress, ARBITRUM, AVALANCHE, ARBITRUM_TESTNET } from "./addresses";

const BigNumber = ethers.BigNumber;
const formatUnits = ethers.utils.formatUnits;
const { JsonRpcProvider } = ethers.providers;

import RewardReader from "../abis/RewardReader.json";
import MlpManager from "../abis/MlpManager.json";
import Token from "../abis/v1/Token.json";
import moment from "moment";

const providers = {
  arbitrum: new JsonRpcProvider("https://arb1.arbitrum.io/rpc"),
  arbitrumTestnet: new JsonRpcProvider("https://rinkeby.arbitrum.io/rpc"),
  avalanche: new JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc"),
};

function getProvider(chainName) {
  if (!(chainName in providers)) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return providers[chainName];
}

function getChainId(chainName) {
  const chainId = {
    arbitrum: ARBITRUM,
    arbitrumTestnet: ARBITRUM_TESTNET,
    avalanche: AVALANCHE,
  }[chainName];
  if (!chainId) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return chainId;
}

const DEFAULT_GROUP_PERIOD = 86400;
const NOW_TS = parseInt(Date.now() / 1000);
export const FIRST_DATE_TS = moment("2022-08-15").unix();

function fillNa(arr, keys) {
  const prevValues = {};
  if (!keys && arr.length > 0) {
    keys = Object.keys(arr[0]);
    delete keys.timestamp;
    delete keys.id;
  }
  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key];
        }
      } else {
        prevValues[key] = el[key];
      }
    }
  }
  return arr;
}

export const USD_DECIMALS = 30;

export async function queryEarnData(chainName, account) {
  const provider = getProvider(chainName);
  const chainId = getChainId(chainName);
  const rewardReader = new ethers.Contract(
    getAddress(chainId, "RewardReader"),
    RewardReader.abi,
    provider
  );
  const mlpContract = new ethers.Contract(
    getAddress(chainId, "MLP"),
    Token.abi,
    provider
  );
  const mlpManager = new ethers.Contract(
    getAddress(chainId, "MlpManager"),
    MlpManager.abi,
    provider
  );

  let depositTokens;
  let rewardTrackersForDepositBalances;
  let rewardTrackersForStakingInfo;

  if (chainId === ARBITRUM) {
    depositTokens = [
      "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0x35247165119B69A40edD5304969560D0ef486921",
      "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    ];
    rewardTrackersForDepositBalances = [
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0x4e971a87900b931fF39d1Aad67697F49835400b6",
    ];
    rewardTrackersForStakingInfo = [
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
      "0x4e971a87900b931fF39d1Aad67697F49835400b6",
    ];
  } else if (chainId === ARBITRUM_TESTNET) {
    depositTokens = [
      "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0x35247165119B69A40edD5304969560D0ef486921",
      "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    ];
    rewardTrackersForDepositBalances = [
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0x4e971a87900b931fF39d1Aad67697F49835400b6",
    ];
    rewardTrackersForStakingInfo = [
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
      "0x4e971a87900b931fF39d1Aad67697F49835400b6",
    ];
  } else {
    depositTokens = [
      "0x62edc0692BD897D2295872a9FFCac5425011c661",
      "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
      "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
      "0x01234181085565ed162a948b6a5e88758CD7c7b8",
    ];
    rewardTrackersForDepositBalances = [
      "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
      "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    ];
    rewardTrackersForStakingInfo = [
      "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0x9e295B5B976a184B14aD8cd72413aD846C299660",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    ];
  }

  const [balances, stakingInfo, mlpTotalSupply, mlpAum, gmxPrice] =
    await Promise.all([
      rewardReader.getDepositBalances(
        account,
        depositTokens,
        rewardTrackersForDepositBalances
      ),
      rewardReader
        .getStakingInfo(account, rewardTrackersForStakingInfo)
        .then((info) => {
          return rewardTrackersForStakingInfo.map((_, i) => {
            return info.slice(i * 5, (i + 1) * 5);
          });
        }),
      mlpContract.totalSupply(),
      mlpManager.getAumInUsdg(true),
      fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=gmx&vs_currencies=usd"
      ).then(async (res) => {
        const j = await res.json();
        return j["gmx"]["usd"];
      }),
    ]);

  const mlpPrice = mlpAum / 1e18 / (mlpTotalSupply / 1e18);
  const now = new Date();

  return {
    MLP: {
      stakedMLP: balances[5] / 1e18,
      pendingETH: stakingInfo[4][0] / 1e18,
      pendingEsGMX: stakingInfo[3][0] / 1e18,
      mlpPrice,
    },
    GMX: {
      stakedGMX: balances[0] / 1e18,
      stakedEsGMX: balances[1] / 1e18,
      pendingETH: stakingInfo[2][0] / 1e18,
      pendingEsGMX: stakingInfo[0][0] / 1e18,
      gmxPrice,
    },
    timestamp: parseInt(now / 1000),
    datetime: now.toISOString(),
  };
}

export const tokenDecimals = {
  "0x82af49447d8a07e3bd95bd0d56f35241523fbab1": 18, // WETH
  "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f": 8, // BTC
  "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8": 6, // USDC
  "0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0": 18, // UNI
  "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9": 6, // USDT
  "0xf97f4df75117a78c1a5a0dbb814af92458539fb4": 18, // LINK
  "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a": 18, // MIM
  "0x17fc002b466eec40dae837fc4be5c67993ddbd6f": 18, // FRAX
  "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1": 18, // DAI
};

export const tokenSymbols = {
  // Arbitrum
  "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f": "BTC",
  "0x82af49447d8a07e3bd95bd0d56f35241523fbab1": "ETH",
  "0xf97f4df75117a78c1a5a0dbb814af92458539fb4": "LINK",
  "0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0": "UNI",
  "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8": "USDC",
  "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9": "USDT",
  "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a": "MIM",
  "0x17fc002b466eec40dae837fc4be5c67993ddbd6f": "FRAX",
  "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1": "DAI",
  "0x040d1edc9569d4bab2d15287dc5a4f10f56a56b8": "BAL",
  "0x11cdb42b0eb46d95f990bedd4695a6e3fa034978": "CRV",
  "0x9d2f299715d94d8a7e6f5eaa8e654e8c74a988a7": "FXS",

  // Avalanche
  "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7": "AVAX",
  "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab": "WETH.e",
  "0x50b7545627a5162f82a992c33b87adc75187b218": "WBTC.e",
  "0x130966628846bfd36ff31a822705796e8cb8c18d": "MIM",
  "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664": "USDC.e",
  "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e": "USDC",
};

function getTokenDecimals(token) {
  return tokenDecimals[token] || 18;
}

const knownSwapSources = {
  arbitrum: {
    "0xabbc5f99639c9b6bcb58544ddf04efa6802f4064": "GMX", // Router
    "0x09f77e8a13de9a35a7231028187e9fd5db8a2acb": "GMX", // Orderbook
    "0x98a00666cfcb2ba5a405415c2bf6547c63bf5491": "GMX", // PositionManager old
    "0x87a4088bd721f83b6c2e5102e2fa47022cb1c831": "GMX", // PositionManager
    "0x7257ac5d0a0aac04aa7ba2ac0a6eb742e332c3fb": "GMX", // OrderExecutor
    "0x1a0ad27350cccd6f7f168e052100b4960efdb774": "GMX", // FastPriceFeed
    "0x3b6067d4caa8a14c63fdbe6318f27a0bbc9f9237": "Dodo",
    "0x11111112542d85b3ef69ae05771c2dccff4faa26": "1inch",
    "0x6352a56caadc4f1e25cd6c75970fa768a3304e64": "OpenOcean", // OpenOceanExchangeProxy
    "0x4775af8fef4809fe10bf05867d2b038a4b5b2146": "Gelato",
    "0x5a9fd7c39a6c488e715437d7b1f3c823d5596ed1": "LiFiDiamond",
    "0x1d838be5d58cc131ae4a23359bc6ad2dddb8b75a": "Vovo", // Vovo BTC UP USDC (vbuUSDC)
    "0xc4bed5eeeccbe84780c44c5472e800d3a5053454": "Vovo", // Vovo ETH UP USDC (veuUSDC)
    "0xe40beb54ba00838abe076f6448b27528dd45e4f0": "Vovo", // Vovo BTC UP USDC (vbuUSDC)
    "0x9ba57a1d3f6c61ff500f598f16b97007eb02e346": "Vovo", // Vovo ETH UP USDC (veuUSDC)
    "0xfa82f1ba00b0697227e2ad6c668abb4c50ca0b1f": "JonesDAO",
    "0x226cb17a52709034e2ec6abe0d2f0a9ebcec1059": "WardenSwap",
    "0x1111111254fb6c44bac0bed2854e76f90643097d": "1inch",
    "0x6d7a3177f3500bea64914642a49d0b5c0a7dae6d": "deBridge",
    "0xc30141b657f4216252dc59af2e7cdb9d8792e1b0": "socket.tech",
  },
  arbitrumTestnet: {
    // '0xabbc5f99639c9b6bcb58544ddf04efa6802f4064': 'GMX', // Router
    // '0x09f77e8a13de9a35a7231028187e9fd5db8a2acb': 'GMX', // Orderbook
    // '0x98a00666cfcb2ba5a405415c2bf6547c63bf5491': 'GMX', // PositionManager old
    // '0x87a4088bd721f83b6c2e5102e2fa47022cb1c831': 'GMX', // PositionManager
    // '0x7257ac5d0a0aac04aa7ba2ac0a6eb742e332c3fb': 'GMX', // OrderExecutor
    // '0x1a0ad27350cccd6f7f168e052100b4960efdb774': 'GMX', // FastPriceFeed
    "0xeFD641e16c597832a3Fcab561A0A5dcc547DF42C": "GMX", // Position Router
    // '0x3b6067d4caa8a14c63fdbe6318f27a0bbc9f9237': 'Dodo',
    // '0x11111112542d85b3ef69ae05771c2dccff4faa26': '1inch',
    // '0x6352a56caadc4f1e25cd6c75970fa768a3304e64': 'OpenOcean', // OpenOceanExchangeProxy
    // '0x4775af8fef4809fe10bf05867d2b038a4b5b2146': 'Gelato',
    // '0x5a9fd7c39a6c488e715437d7b1f3c823d5596ed1': 'LiFiDiamond',
    // '0x1d838be5d58cc131ae4a23359bc6ad2dddb8b75a': 'Vovo', // Vovo BTC UP USDC (vbuUSDC)
    // '0xc4bed5eeeccbe84780c44c5472e800d3a5053454': 'Vovo', // Vovo ETH UP USDC (veuUSDC)
    // '0xe40beb54ba00838abe076f6448b27528dd45e4f0': 'Vovo', // Vovo BTC UP USDC (vbuUSDC)
    // '0x9ba57a1d3f6c61ff500f598f16b97007eb02e346': 'Vovo', // Vovo ETH UP USDC (veuUSDC)
    // '0xfa82f1ba00b0697227e2ad6c668abb4c50ca0b1f': 'JonesDAO',
    // '0x226cb17a52709034e2ec6abe0d2f0a9ebcec1059': 'WardenSwap',
    // '0x1111111254fb6c44bac0bed2854e76f90643097d': '1inch',
    // '0x6d7a3177f3500bea64914642a49d0b5c0a7dae6d': 'deBridge',
    // '0xc30141b657f4216252dc59af2e7cdb9d8792e1b0': 'socket.tech'
  },
  avalanche: {
    "0x4296e307f108b2f583ff2f7b7270ee7831574ae5": "GMX",
    "0x5f719c2f1095f7b9fc68a68e35b51194f4b6abe8": "GMX",
    "0x7d9d108445f7e59a67da7c16a2ceb08c85b76a35": "GMX", // FastPriceFeed
    "0xf2ec2e52c3b5f8b8bd5a3f93945d05628a233216": "GMX", // PositionManager
    "0xc4729e56b831d74bbc18797e0e17a295fa77488c": "Yak",
    "0x409e377a7affb1fd3369cfc24880ad58895d1dd9": "Dodo",
    "0x6352a56caadc4f1e25cd6c75970fa768a3304e64": "OpenOcean",
    "0x7c5c4af1618220c090a6863175de47afb20fa9df": "Gelato",
    "0x1111111254fb6c44bac0bed2854e76f90643097d": "1inch",
    "0xdef171fe48cf0115b1d80b88dc8eab59176fee57": "ParaSwap",
    "0x2ecf2a2e74b19aab2a62312167aff4b78e93b6c5": "ParaSwap",
  },
};

const defaultFetcher = (url) => fetch(url).then((res) => res.json());
export function useRequest(url, defaultValue, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      console.error(ex);
      setError(ex);
    }
    setLoading(false);
  }, [url]);

  return [data, loading, error];
}

export function useCoingeckoPrices(symbol, { from = FIRST_DATE_TS } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    BTC: "bitcoin",
    ETH: "ethereum",
    LINK: "chainlink",
    UNI: "uniswap",
    AVAX: "avalanche-2",
  }[symbol];

  const now = Date.now() / 1000;
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1;

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`;

  const [res, loading, error] = useRequest(url);

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null;
    }

    const ret = res.prices.map((item) => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for MLP we store last price of the day
      const timestamp = item[0] - 1;
      const groupTs = parseInt(timestamp / 1000 / 86400) * 86400;
      return {
        timestamp: groupTs,
        value: item[1],
      };
    });
    return ret;
  }, [res]);

  return [data, loading, error];
}

function getImpermanentLoss(change) {
  return (2 * Math.sqrt(change)) / (1 + change) - 1;
}

export function getChainSubgraph(chainName) {
  switch (chainName) {
    case "arbitrum":
      return "mycelium-ethereum/myc-swaps-stats";
    case "arbitrumTestnet":
      return "tracer-protocol/arbitrum-rinkeby-gmx-stats";
  }
  throw Error("Invalid network");
}

export function useGraph(
  querySource,
  { subgraph = null, subgraphUrl = null, chainName = "arbitrum" } = {}
) {
  const query = gql(querySource);

  if (!subgraphUrl) {
    if (!subgraph) {
      subgraph = getChainSubgraph(chainName);
    }
    subgraphUrl = `https://api.thegraph.com/subgraphs/name/${subgraph}`;
  }

  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  });
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, [querySource, setLoading]);

  useEffect(() => {
    client
      .query({ query })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((ex) => {
        console.warn(
          "Subgraph request failed error: %s subgraphUrl: %s",
          ex.message,
          subgraphUrl
        );
        setError(ex);
        setLoading(false);
      });
  }, [querySource, setData, setError, setLoading]);

  return [data, loading, error];
}

export function useGambitVolumeData({ from, to }) {
  const [graphData, loading, error] = useGraph(
    `{
    volumeStats(
      first: 1000,
      where: { id_gte: ${from}, id_lte: ${to}, period: daily }
      orderBy: id
      orderDirection: desc
    ) {
      id
      margin
      swap
      liquidation
      mint
      burn
    }
  }`,
    {
      subgraph: "gmx-io/gmx-bsc-stats",
    }
  );

  let data;
  if (graphData) {
    data = sortBy(graphData.volumeStats, (item) => item.id).map(
      ({ id, margin, swap, liquidation, mint, burn }) => {
        margin = margin / 1e30;
        swap = swap / 1e30;
        liquidation = liquidation / 1e30;
        mint = mint / 1e30;
        burn = burn / 1e30;
        const all = margin + swap + liquidation + mint + burn;
        return {
          timestamp: id,
          all,
          margin,
          swap,
          liquidation,
          mint,
          burn,
        };
      }
    );
  }

  return [data, loading];
}

export function useGambitFeesData({ from, to }) {
  const [graphData, loading, error] = useGraph(
    `{
    feeStats(
      first: 1000,
      where: { id_gte: ${from}, id_lte: ${to}, period: daily }
      orderBy: id
      orderDirection: desc
    ) {
      id
      margin
      swap
      mint
      burn
      marginCumulative
      swapCumulative
      liquidationCumulative
      mintCumulative
      burnCumulative
    }
  }`,
    {
      subgraph: "gmx-io/gmx-bsc-stats",
    }
  );

  let data;
  if (graphData) {
    data = sortBy(graphData.feeStats, (item) => item.id).map(
      ({ id, margin, swap, mint, burn }) => {
        margin = margin / 1e30;
        swap = swap / 1e30;
        const liquidation = 0;
        mint = mint / 1e30;
        burn = burn / 1e30;
        const all = margin + swap + mint + burn;
        return {
          timestamp: id,
          all,
          margin,
          swap,
          liquidation,
          mint,
          burn,
        };
      }
    );
  }

  return [data, loading];
}

export function useGambitPoolStats({ from, to, groupPeriod }) {
  const [data, loading, error] = useGraph(
    `{
    poolStats (
      first: 1000,
      where: { id_gte: ${from}, id_lte: ${to} }
      orderBy: id
      orderDirection: desc
    ) {
      id,
      usdgSupply,
      BTC_usd,
      ETH_usd,
      BNB_usd,
      USDC_usd,
      USDT_usd,
      BUSD_usd
    }
  }`,
    { subgraph: "gkrasulya/gambit" }
  );

  const ret = useMemo(() => {
    if (!data) {
      return null;
    }
    let ret = data.poolStats.map((item) => {
      return Object.entries(item).reduce((memo, [key, value]) => {
        if (key === "id") memo.timestamp = value;
        else if (key === "usdgSupply") memo.usdgSupply = value / 1e18;
        else memo[key.substr(0, key.length - 4)] = value / 1e30;
        return memo;
      }, {});
    });

    ret = chain(ret)
      .sortBy("timestamp")
      .groupBy((item) => Math.floor(item.timestamp / groupPeriod) * groupPeriod)
      .map((values, timestamp) => {
        return {
          ...values[values.length - 1],
          timestamp,
        };
      })
      .value();

    return fillPeriods(ret, {
      period: groupPeriod,
      from,
      to,
      interpolate: false,
      extrapolate: true,
    });
  }, [data]);

  return [ret, loading, error];
}

export function useLastBlock(chainName = "arbitrum") {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    providers[chainName]
      .getBlock()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return [data, loading, error];
}

export function useLastSubgraphBlock(chainName = "arbitrum") {
  const [data, loading, error] = useGraph(
    `{
    _meta {
      block {
        number
      }
    }
  }`,
    { chainName }
  );
  const [block, setBlock] = useState(null);

  useEffect(() => {
    if (!data) {
      return;
    }

    providers[chainName].getBlock(data._meta.block.number).then((block) => {
      setBlock(block);
    });
  }, [data, setBlock]);

  return [block, loading, error];
}

export function useTradersData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const [closedPositionsData, loading, error] = useGraph(
    `{
    tradingStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      timestamp
      profit
      loss
      profitCumulative
      lossCumulative
      longOpenInterest
      shortOpenInterest
    }
  }`,
    { chainName }
  );
  const [feesData] = useFeesData({ from, to, chainName });
  const marginFeesByTs = useMemo(() => {
    if (!feesData) {
      return {};
    }

    let feesCumulative = 0;
    return feesData.reduce((memo, { timestamp, margin: fees }) => {
      feesCumulative += fees;
      memo[timestamp] = {
        fees,
        feesCumulative,
      };
      return memo;
    }, {});
  }, [feesData]);

  let ret = null;
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;
  const data = closedPositionsData
    ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp).map(
        (dataItem) => {
          const longOpenInterest = dataItem.longOpenInterest / 1e30;
          const shortOpenInterest = dataItem.shortOpenInterest / 1e30;
          const openInterest = longOpenInterest + shortOpenInterest;

          // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
          // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

          const profit = dataItem.profit / 1e30;
          const loss = dataItem.loss / 1e30;
          const profitCumulative = dataItem.profitCumulative / 1e30;
          const lossCumulative = dataItem.lossCumulative / 1e30;
          const pnlCumulative = profitCumulative - lossCumulative;
          const pnl = profit - loss;
          currentProfitCumulative += profit;
          currentLossCumulative -= loss;
          currentPnlCumulative += pnl;
          return {
            longOpenInterest,
            shortOpenInterest,
            openInterest,
            profit,
            loss: -loss,
            profitCumulative,
            lossCumulative: -lossCumulative,
            pnl,
            pnlCumulative,
            timestamp: dataItem.timestamp,
            currentPnlCumulative,
            currentLossCumulative,
            currentProfitCumulative,
          };
        }
      )
    : null;

  if (data && data.length !== 0) {
    const maxProfit = maxBy(data, (item) => item.profit).profit;
    const maxLoss = minBy(data, (item) => item.loss).loss;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl).pnl;
    const minPnl = minBy(data, (item) => item.pnl).pnl;
    const maxCurrentCumulativePnl = maxBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;
    const minCurrentCumulativePnl = minBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;

    const currentProfitCumulative =
      data[data.length - 1].currentProfitCumulative;
    const currentLossCumulative = data[data.length - 1].currentLossCumulative;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(
        currentProfitCumulative,
        -currentLossCumulative
      ),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),
    };

    ret = {
      data,
      stats,
    };
  }

  return [ret, loading];
}

function getSwapSourcesFragment(skip = 0, from, to, chainName) {
  if (chainName === "arbitrumTestnet") {
    return `
      hourlyVolumeBySources(
        first: 1000
        skip: ${skip}
        orderBy: timestamp
        orderDirection: desc
        where: { timestamp_gte: ${from}, timestamp_lte: ${to}, sourceType: "contract"}
      ) {
        timestamp
        source
        swap
      }
    `;
  }
  return `
    hourlyVolumeBySources(
      first: 1000
      skip: ${skip}
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gte: ${from}, timestamp_lte: ${to}}
    ) {
      timestamp
      source
      swap
    }
  `;
}
export function useSwapSources({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const query = `{
    a: ${getSwapSourcesFragment(0, from, to, chainName)}
    b: ${getSwapSourcesFragment(1000, from, to, chainName)}
    c: ${getSwapSourcesFragment(2000, from, to, chainName)}
    d: ${getSwapSourcesFragment(3000, from, to, chainName)}
    e: ${getSwapSourcesFragment(4000, from, to, chainName)}
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  let total = 0;
  let data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const { a, b, c, d, e } = graphData;
    const all = [...a, ...b, ...c, ...d, ...e];

    const totalVolumeBySource = a.reduce((acc, item) => {
      const source = knownSwapSources[chainName][item.source] || item.source;
      if (!acc[source]) {
        acc[source] = 0;
      }
      acc[source] += item.swap / 1e30;
      return acc;
    }, {});
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource)
        .sort((a, b) => b[1] - a[1])
        .map((item) => item[0])
        .slice(0, 30)
    );

    let ret = chain(all)
      .groupBy((item) => parseInt(item.timestamp / 86400) * 86400)
      .map((values, timestamp) => {
        let all = 0;
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            let source =
              knownSwapSources[chainName][item.source] || item.source;
            if (!topVolumeSources.has(source)) {
              source = "Other";
            }
            if (item.swap != 0) {
              const volume = item.swap / 1e30;
              memo[source] = memo[source] || 0;
              memo[source] += volume;
              all += volume;
            }
            return memo;
          }, {}),
        };

        retItem.all = all;

        return retItem;
      })
      .sortBy((item) => item.timestamp)
      .value();

    return ret;
  }, [graphData]);

  return [data, loading, error];
}

export function useTotalVolumeFromServer({ chainName = "arbitrum" }) {
  const chainId = getChainId(chainName);
  const [data, loading] = useRequest(
    `https://api.tracer.finance/trs/volume?network=${chainId}`
  );

  if (!data) {
    return [null, loading];
  }

  const totalVolume = parseInt(data.totalVolume) / 1e30;

  return [totalVolume, loading];
}

function getServerHostname(chainName) {
  if (chainName == "avalanche") {
    return "gmx-avax-server.uc.r.appspot.com";
  }
  return "gmx-server-mainnet.uw.r.appspot.com";
}

export function useVolumeDataRequest(
  url,
  defaultValue,
  from,
  to,
  fetcher = defaultFetcher
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      console.error(ex);
      setError(ex);
    }
    setLoading(false);
  }, [url, from, to]);

  return [data, loading, error];
}

export function useVolumeDataFromServer({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const PROPS = ["margin", "liquidation", "swap", "mint", "burn"];
  const [data, loading] = useVolumeDataRequest(
    `https://${getServerHostname(chainName)}/daily_volume`,
    null,
    from,
    to,
    async (url) => {
      let after;
      const ret = [];
      while (true) {
        const res = await (
          await fetch(url + (after ? `?after=${after}` : ""))
        ).json();
        if (res.length === 0) return ret;
        for (const item of res) {
          if (item.data.timestamp < from) {
            return ret;
          }
          ret.push(item);
        }
        after = res[res.length - 1].id;
      }
    }
  );

  const ret = useMemo(() => {
    if (!data) {
      return null;
    }

    const tmp = data.reduce((memo, item) => {
      const timestamp = item.data.timestamp;
      if (timestamp < from || timestamp > to) {
        return memo;
      }

      let type;
      if (item.data.action === "Swap") {
        type = "swap";
      } else if (item.data.action === "SellUSDG") {
        type = "burn";
      } else if (item.data.action === "BuyUSDG") {
        type = "mint";
      } else if (item.data.action.includes("LiquidatePosition")) {
        type = "liquidation";
      } else {
        type = "margin";
      }
      const volume = Number(item.data.volume) / 1e30;
      memo[timestamp] = memo[timestamp] || {};
      memo[timestamp][type] = memo[timestamp][type] || 0;
      memo[timestamp][type] += volume;
      return memo;
    }, {});

    let cumulative = 0;
    const cumulativeByTs = {};
    return Object.keys(tmp)
      .sort()
      .map((timestamp) => {
        const item = tmp[timestamp];
        let all = 0;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        PROPS.forEach((prop) => {
          if (item[prop]) all += item[prop];
        });
        cumulative += all;
        cumulativeByTs[timestamp] = cumulative;
        return {
          timestamp,
          all,
          cumulative,
          movingAverageAll,
          ...item,
        };
      });
  }, [data, from, to]);

  return [ret, loading];
}

export function useUsersData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const query = `{
    userStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      uniqueCount
      uniqueSwapCount
      uniqueMarginCount
      uniqueMintBurnCount
      uniqueCountCumulative
      uniqueSwapCountCumulative
      uniqueMarginCountCumulative
      uniqueMintBurnCountCumulative
      actionCount
      actionSwapCount
      actionMarginCount
      actionMintBurnCount
      timestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const prevUniqueCountCumulative = {};
  let cumulativeNewUserCount = 0;
  const data = graphData
    ? sortBy(graphData.userStats, "timestamp").map((item) => {
        const newCountData = ["", "Swap", "Margin", "MintBurn"].reduce(
          (memo, type) => {
            memo[`new${type}Count`] = prevUniqueCountCumulative[type]
              ? item[`unique${type}CountCumulative`] -
                prevUniqueCountCumulative[type]
              : item[`unique${type}Count`];
            prevUniqueCountCumulative[type] =
              item[`unique${type}CountCumulative`];
            return memo;
          },
          {}
        );
        cumulativeNewUserCount += newCountData.newCount;
        const oldCount = item.uniqueCount - newCountData.newCount;
        const oldPercent = ((oldCount / item.uniqueCount) * 100).toFixed(1);
        return {
          all: item.uniqueCount,
          uniqueSum:
            item.uniqueSwapCount +
            item.uniqueMarginCount +
            item.uniqueMintBurnCount,
          oldCount,
          oldPercent,
          cumulativeNewUserCount,
          ...newCountData,
          ...item,
        };
      })
    : null;

  return [data, loading, error];
}

export function useFundingRateData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const query = `{
    fundingRates(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
    ) {
      id,
      token,
      timestamp,
      startFundingRate,
      startTimestamp,
      endFundingRate,
      endTimestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const groups = graphData.fundingRates.reduce((memo, item) => {
      const symbol = tokenSymbols[item.token];
      if (symbol === "MIM") {
        return memo;
      }
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp,
      };
      const group = memo[item.timestamp];
      const timeDelta =
        parseInt((item.endTimestamp - item.startTimestamp) / 3600) * 3600;

      let fundingRate = 0;
      if (item.endFundingRate && item.startFundingRate) {
        const fundingDelta = item.endFundingRate - item.startFundingRate;
        const divisor = timeDelta / 86400;
        fundingRate = (fundingDelta / divisor / 10000) * 365;
      }
      group[symbol] = fundingRate;
      return memo;
    }, {});

    return fillNa(sortBy(Object.values(groups), "timestamp"), [
      "ETH",
      "USDC",
      "USDT",
      "BTC",
      "LINK",
      "UNI",
      "MIM",
    ]);
  }, [graphData]);

  return [data, loading, error];
}

const MOVING_AVERAGE_DAYS = 7;
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS;

export function useVolumeData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const PROPS = ["margin", "liquidation", "swap", "mint", "burn"];
  const timestampProp = "id";
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: ${timestampProp},
      orderDirection: desc
      where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
    ) {
      ${timestampProp}
      ${PROPS.join("\n")}
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    let ret = sortBy(graphData.volumeStats, timestampProp).map((item) => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30;
        all += ret[prop];
      });
      ret.all = all;
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return ret.map((item) => {
      cumulative += item.all;

      let movingAverageAll;
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD;
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll =
          (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
      }

      return {
        movingAverageAll,
        cumulative,
        ...item,
      };
    });
  }, [graphData]);

  return [data, loading, error];
}

export function useFeesData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const PROPS = ["margin", "liquidation", "swap", "mint", "burn"];
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, id_gte: ${from}, id_lte: ${to} }
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
    }
  }`;
  let [feesData, loading, error] = useGraph(feesQuery, {
    chainName,
  });

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null;
    }

    let chartData = sortBy(feesData.feeStats, "id").map((item) => {
      const ret = { timestamp: item.timestamp || item.id };

      PROPS.forEach((prop) => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30;
        }
      });

      ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30;
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0);
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return chain(chartData)
      .groupBy((item) => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, "all");
        cumulative += all;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        const ret = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll,
        };
        PROPS.forEach((prop) => {
          ret[prop] = sumBy(values, prop);
        });
        cumulativeByTs[timestamp] = cumulative;
        return ret;
      })
      .filter((item) => item.timestamp >= from)
      .value();
  }, [feesData]);

  return [feesChartData, loading, error];
}

export function useAumPerformanceData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  groupPeriod,
}) {
  const [feesData, feesLoading] = useFeesData({ from, to, groupPeriod });
  const [mlpData, mlpLoading] = useMlpData({ from, to, groupPeriod });
  const [volumeData, volumeLoading] = useVolumeData({ from, to, groupPeriod });

  const dailyCoef = 86400 / groupPeriod;

  const data = useMemo(() => {
    if (!feesData || !mlpData || !volumeData) {
      return null;
    }

    const ret = feesData.map((feeItem, i) => {
      const mlpItem = mlpData[i];
      const volumeItem = volumeData[i];
      let apr =
        feeItem?.all && mlpItem?.aum
          ? (feeItem.all / mlpItem.aum) * 100 * 365 * dailyCoef
          : null;
      if (apr > 10000) {
        apr = null;
      }
      let usage =
        volumeItem?.all && mlpItem?.aum
          ? (volumeItem.all / mlpItem.aum) * 100 * dailyCoef
          : null;
      if (usage > 10000) {
        usage = null;
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage,
      };
    });
    const averageApr =
      ret.reduce((memo, item) => item.apr + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageApr = averageApr));
    const averageUsage =
      ret.reduce((memo, item) => item.usage + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageUsage = averageUsage));
    return ret;
  }, [feesData, mlpData, volumeData]);

  return [data, feesLoading || mlpLoading || volumeLoading];
}

export function useMlpData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const timestampProp = "id";
  const query = `{
    mlpStats(
      first: 1000
      orderBy: ${timestampProp}
      orderDirection: desc
      where: {period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to}}
    ) {
      ${timestampProp}
      aumInUsdg
      mlpSupply
      distributedUsd
      distributedEth
    }
  }`;
  let [data, loading, error] = useGraph(query, { chainName });

  let cumulativeDistributedUsdPerMlp = 0;
  let cumulativeDistributedEthPerMlp = 0;
  const mlpChartData = useMemo(() => {
    if (!data) {
      return null;
    }

    const getTimestamp = (item) =>
      item.timestamp || parseInt(item[timestampProp]);

    let prevMlpSupply;
    let prevAum;

    let ret = sortBy(data.mlpStats, (item) => item[timestampProp])
      .filter((item) => item[timestampProp] % 86400 === 0)
      .reduce((memo, item) => {
        const last = memo[memo.length - 1];

        const aum = Number(item.aumInUsdg) / 1e18;
        const mlpSupply = Number(item.mlpSupply) / 1e18;

        const distributedUsd = Number(item.distributedUsd) / 1e30;
        const distributedUsdPerMlp = distributedUsd / mlpSupply || 0;
        cumulativeDistributedUsdPerMlp += distributedUsdPerMlp;

        const distributedEth = Number(item.distributedEth) / 1e18;
        const distributedEthPerMlp = distributedEth / mlpSupply || 0;
        cumulativeDistributedEthPerMlp += distributedEthPerMlp;

        const mlpPrice = aum / mlpSupply;
        const timestamp = parseInt(item[timestampProp]);

        const newItem = {
          timestamp,
          aum,
          mlpSupply,
          mlpPrice,
          cumulativeDistributedEthPerMlp,
          cumulativeDistributedUsdPerMlp,
          distributedUsdPerMlp,
          distributedEthPerMlp,
        };

        if (last && last.timestamp === timestamp) {
          memo[memo.length - 1] = newItem;
        } else {
          memo.push(newItem);
        }

        return memo;
      }, [])
      .map((item) => {
        let { mlpSupply, aum } = item;
        if (!mlpSupply) {
          mlpSupply = prevMlpSupply;
        }
        if (!aum) {
          aum = prevAum;
        }
        item.mlpSupplyChange = prevMlpSupply
          ? ((mlpSupply - prevMlpSupply) / prevMlpSupply) * 100
          : 0;
        if (item.mlpSupplyChange > 1000) item.mlpSupplyChange = 0;
        item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0;
        if (item.aumChange > 1000) item.aumChange = 0;
        prevMlpSupply = mlpSupply;
        prevAum = aum;
        return item;
      });

    ret = fillNa(ret);
    return ret;
  }, [data]);

  return [mlpChartData, loading, error];
}

export function useMlpPerformanceData(
  mlpData,
  feesData,
  { from = FIRST_DATE_TS, chainName = "arbitrum" } = {}
) {
  const [btcPrices] = useCoingeckoPrices("BTC", { from });
  const [ethPrices] = useCoingeckoPrices("ETH", { from });
  const [avaxPrices] = useCoingeckoPrices("AVAX", { from });

  const mlpPerformanceChartData = useMemo(() => {
    if (!btcPrices || !ethPrices || !avaxPrices || !mlpData || !feesData) {
      return null;
    }

    const mlpDataById = mlpData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    }, {});

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    }, {});

    let BTC_WEIGHT = 0;
    let ETH_WEIGHT = 0;
    let AVAX_WEIGHT = 0;

    if (chainName === "avalanche") {
      BTC_WEIGHT = 0.166;
      ETH_WEIGHT = 0.166;
      AVAX_WEIGHT = 0.166;
    } else {
      BTC_WEIGHT = 0.25;
      ETH_WEIGHT = 0.25;
    }

    const STABLE_WEIGHT = 1 - BTC_WEIGHT - ETH_WEIGHT - AVAX_WEIGHT;
    const MLP_START_PRICE =
      mlpDataById[btcPrices[0].timestamp]?.mlpPrice || 1.19;

    const btcFirstPrice = btcPrices[0]?.value;
    const ethFirstPrice = ethPrices[0]?.value;
    const avaxFirstPrice = avaxPrices[0]?.value;

    let indexBtcCount = (MLP_START_PRICE * BTC_WEIGHT) / btcFirstPrice;
    let indexEthCount = (MLP_START_PRICE * ETH_WEIGHT) / ethFirstPrice;
    let indexAvaxCount = (MLP_START_PRICE * AVAX_WEIGHT) / avaxFirstPrice;
    let indexStableCount = MLP_START_PRICE * STABLE_WEIGHT;

    const lpBtcCount = (MLP_START_PRICE * 0.5) / btcFirstPrice;
    const lpEthCount = (MLP_START_PRICE * 0.5) / ethFirstPrice;
    const lpAvaxCount = (MLP_START_PRICE * 0.5) / avaxFirstPrice;

    const ret = [];
    let cumulativeFeesPerMlp = 0;
    let cumulativeEsgmxRewardsPerMlp = 0;
    let lastMlpPrice = 0;

    let prevEthPrice = 3400;
    let prevAvaxPrice = 1000;
    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value;
      const ethPrice = ethPrices[i]?.value || prevEthPrice;
      const avaxPrice = avaxPrices[i]?.value || prevAvaxPrice;
      prevAvaxPrice = avaxPrice;
      prevEthPrice = ethPrice;

      const timestampGroup = parseInt(btcPrices[i].timestamp / 86400) * 86400;
      if (timestampGroup < FIRST_DATE_TS) continue;
      const mlpItem = mlpDataById[timestampGroup];
      const mlpPrice = mlpItem?.mlpPrice ?? lastMlpPrice;
      lastMlpPrice = mlpPrice;
      const mlpSupply = mlpDataById[timestampGroup]?.mlpSupply;
      const dailyFees = feesDataById[timestampGroup]?.all;

      const syntheticPrice =
        indexBtcCount * btcPrice +
        indexEthCount * ethPrice +
        indexAvaxCount * avaxPrice +
        indexStableCount;

      // rebalance each day. can rebalance each X days
      if (i % 1 == 0) {
        indexBtcCount = (syntheticPrice * BTC_WEIGHT) / btcPrice;
        indexEthCount = (syntheticPrice * ETH_WEIGHT) / ethPrice;
        indexAvaxCount = (syntheticPrice * AVAX_WEIGHT) / avaxPrice;
        indexStableCount = syntheticPrice * STABLE_WEIGHT;
      }

      const lpBtcPrice =
        (lpBtcCount * btcPrice + MLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(btcPrice / btcFirstPrice));
      const lpEthPrice =
        (lpEthCount * ethPrice + MLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(ethPrice / ethFirstPrice));
      const lpAvaxPrice =
        (lpAvaxCount * avaxPrice + MLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(avaxPrice / avaxFirstPrice));

      if (dailyFees && mlpSupply) {
        const INCREASED_MLP_REWARDS_TIMESTAMP = 1635714000;
        const MLP_REWARDS_SHARE =
          timestampGroup >= INCREASED_MLP_REWARDS_TIMESTAMP ? 0.7 : 0.5;
        const collectedFeesPerMlp = (dailyFees / mlpSupply) * MLP_REWARDS_SHARE;
        cumulativeFeesPerMlp += collectedFeesPerMlp;

        cumulativeEsgmxRewardsPerMlp += (mlpPrice * 0.8) / 365;
      }

      let mlpPlusFees = mlpPrice;
      if (mlpPrice && mlpSupply && cumulativeFeesPerMlp) {
        mlpPlusFees = mlpPrice + cumulativeFeesPerMlp;
      }

      let mlpApr;
      let mlpPlusDistributedUsd;
      let mlpPlusDistributedEth;
      if (mlpItem) {
        if (mlpItem.cumulativeDistributedUsdPerMlp) {
          mlpPlusDistributedUsd =
            mlpPrice + mlpItem.cumulativeDistributedUsdPerMlp;
          // mlpApr = mlpItem.distributedUsdPerMlp / mlpPrice * 365 * 100 // incorrect?
        }
        if (mlpItem.cumulativeDistributedEthPerMlp) {
          mlpPlusDistributedEth =
            mlpPrice + mlpItem.cumulativeDistributedEthPerMlp * ethPrice;
        }
      }

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        lpAvaxPrice,
        mlpPrice,
        btcPrice,
        ethPrice,
        mlpPlusFees,
        mlpPlusDistributedUsd,
        mlpPlusDistributedEth,

        indexBtcCount,
        indexEthCount,
        indexAvaxCount,
        indexStableCount,

        BTC_WEIGHT,
        ETH_WEIGHT,
        AVAX_WEIGHT,
        STABLE_WEIGHT,

        performanceLpEth: ((mlpPrice / lpEthPrice) * 100).toFixed(2),
        performanceLpEthCollectedFees: (
          (mlpPlusFees / lpEthPrice) *
          100
        ).toFixed(2),
        performanceLpEthDistributedUsd: (
          (mlpPlusDistributedUsd / lpEthPrice) *
          100
        ).toFixed(2),
        performanceLpEthDistributedEth: (
          (mlpPlusDistributedEth / lpEthPrice) *
          100
        ).toFixed(2),

        performanceLpBtcCollectedFees: (
          (mlpPlusFees / lpBtcPrice) *
          100
        ).toFixed(2),

        performanceLpAvaxCollectedFees: (
          (mlpPlusFees / lpAvaxPrice) *
          100
        ).toFixed(2),

        performanceSynthetic: ((mlpPrice / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticCollectedFees: (
          (mlpPlusFees / syntheticPrice) *
          100
        ).toFixed(2),
        performanceSyntheticDistributedUsd: (
          (mlpPlusDistributedUsd / syntheticPrice) *
          100
        ).toFixed(2),
        performanceSyntheticDistributedEth: (
          (mlpPlusDistributedEth / syntheticPrice) *
          100
        ).toFixed(2),

        mlpApr,
      });
    }

    return ret;
  }, [btcPrices, ethPrices, mlpData, feesData]);

  return [mlpPerformanceChartData];
}

export function useTokenStats({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  period = "daily",
  chainName = "arbitrum",
} = {}) {
  const getTokenStatsFragment = ({ skip = 0 } = {}) => `
    tokenStats(
      first: 1000,
      skip: ${skip},
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      poolAmountUsd
      timestamp
      token
    }
  `;

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
    a: ${getTokenStatsFragment()}
    b: ${getTokenStatsFragment({ skip: 1000 })},
    c: ${getTokenStatsFragment({ skip: 2000 })},
    d: ${getTokenStatsFragment({ skip: 3000 })},
    e: ${getTokenStatsFragment({ skip: 4000 })},
    f: ${getTokenStatsFragment({ skip: 5000 })},
  }`;

  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null;
    }

    const fullData = Object.values(graphData).reduce((memo, records) => {
      memo.push(...records);
      return memo;
    }, []);

    const retrievedTokens = new Set();

    const timestampGroups = fullData.reduce((memo, item) => {
      const { timestamp, token, ...stats } = item;

      const symbol = tokenSymbols[token] || token;

      retrievedTokens.add(symbol);

      memo[timestamp] = memo[timestamp || 0] || {};

      memo[timestamp][symbol] = {
        poolAmountUsd: parseInt(stats.poolAmountUsd) / 1e30,
      };

      return memo;
    }, {});

    const poolAmountUsdRecords = [];

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]) => {
      const poolAmountUsdRecord = Object.entries(dataItem).reduce(
        (memo, [token, stats]) => {
          memo.all += stats.poolAmountUsd;
          memo[token] = stats.poolAmountUsd;
          memo.timestamp = timestamp;

          return memo;
        },
        { all: 0 }
      );

      poolAmountUsdRecords.push(poolAmountUsdRecord);
    });

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    };
  }, [graphData, loading]);

  return [data, loading, error];
}

export function useReferralsData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const query = `{
    globalStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      volume
      volumeCumulative
      totalRebateUsd
      totalRebateUsdCumulative
      discountUsd
      discountUsdCumulative
      referrersCount
      referrersCountCumulative
      referralCodesCount
      referralCodesCountCumulative
      referralsCount
      referralsCountCumulative
      timestamp
    }
  }`;
  const subgraph =
    chainName === "arbitrum"
      ? "gdev8317/gmx-arbitrum-referrals-staging"
      : "gdev8317/gmx-avalanche-referrals-staging";
  const [graphData, loading, error] = useGraph(query, { subgraph });

  const data = graphData
    ? sortBy(graphData.globalStats, "timestamp").map((item) => {
        const totalRebateUsd = item.totalRebateUsd / 1e30;
        const discountUsd = item.discountUsd / 1e30;
        return {
          ...item,
          volume: item.volume / 1e30,
          volumeCumulative: item.volumeCumulative / 1e30,
          totalRebateUsd,
          totalRebateUsdCumulative: item.totalRebateUsdCumulative / 1e30,
          discountUsd,
          referrerRebateUsd: totalRebateUsd - discountUsd,
          discountUsdCumulative: item.discountUsdCumulative / 1e30,
          referralCodesCount: parseInt(item.referralCodesCount),
          referralCodesCountCumulative: parseInt(
            item.referralCodesCountCumulative
          ),
          referrersCount: parseInt(item.referrersCount),
          referrersCountCumulative: parseInt(item.referrersCountCumulative),
          referralsCount: parseInt(item.referralsCount),
          referralsCountCumulative: parseInt(item.referralsCountCumulative),
        };
      })
    : null;

  return [data, loading, error];
}
