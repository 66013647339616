import React, { useMemo, useEffect } from "react";
import { useTokenStats } from "../../dataProvider";
import { COINCOLORS, convertToPercents } from "../../helpers";
import GenericChart from "../GenericChart";

const convertToPercentsHandler = (data) =>
  convertToPercents(data, { totalKey: "all", ignoreKeys: [] });

function getTokenColor(index) {
  return COINCOLORS[index % COINCOLORS.length];
}

export default function PoolAmountChart({
  from,
  to,
  period,
  chainName,
  syncId,
}) {
  const [tokenStatsData] = useTokenStats({
    from,
    to,
    period,
    chainName,
  });

  const chartLegendItems =
    tokenStatsData && tokenStatsData.tokenSymbols
      ? tokenStatsData.tokenSymbols.map((token, i) => ({
          key: token,
          color: getTokenColor(i),
          fillOpacity: 0.5,
        }))
      : [];

  // Fill gaps in token data where no value was changed since last bucket
  const filledData = useMemo(
    () =>
      tokenStatsData?.tokenSymbols &&
      tokenStatsData?.poolAmountUsd?.map(
        (item, i) => {
          tokenStatsData.tokenSymbols.forEach((token) => {
            // If the current bucket has no value for this token, use the previous bucket's value
            if (!item[token]) {
              if (i !== 0 && tokenStatsData.poolAmountUsd[i - 1][token]) {
                item[token] = tokenStatsData.poolAmountUsd[i - 1][token];
              }
              // If there is no previous value found, use latest value
              if (!item[token]) {
                for (let k = i; k <= tokenStatsData.poolAmountUsd.length; k++) {
                  if (tokenStatsData.poolAmountUsd[k][token]) {
                    item[token] = tokenStatsData.poolAmountUsd[k][token];
                    break;
                  }
                }
              }
              // If there were no values found at all, use 0
              if (!item[token]) {
                item[token] = 0;
              }
              // Add the new value to the total amount "all"
              item.all += item[token];
            }
          });
          return item;
        },
        [
          tokenStatsData.poolAmountUsd,
          tokenStatsData.tokenSymbols,
          from,
          to,
          period,
          chainName,
        ]
      )
  );

  return (
    <GenericChart
      syncId={syncId}
      loading={!filledData}
      title="Pool Composition (hourly)"
      data={filledData ? filledData : null}
      controls={{
        convertToPercents: convertToPercentsHandler,
      }}
      yaxisDataKey="all"
      items={chartLegendItems}
      type="Area"
    />
  );
}
